import React from 'react';
import Button from 'threads5/Button';
import useTheme from 'threads5/styles/useTheme';
import { withTrailingSlash } from '../utilities';

interface CardProps {
  icon: React.ReactElement;
  title: string;
  link: string;
}

const SmallCard: React.FC<CardProps> = (props: CardProps) => {
  return (
    <Button
      size='48'
      href={withTrailingSlash(props.link)}
      variant='outlined'
      intent='neutral'
      startIcon={props.icon}
      sx={{
        borderColor: `rgb(222, 227, 229) !important`,
        '&:hover': {
          borderColor: `rgb(17, 17,18) !important`,
        },
      }}
    >
      <span>{props.title}</span>
    </Button>
  );
};

SmallCard.displayName = 'SmallCard';

export default SmallCard;
