// TODO: prettier ignore line-break more than 60 chars

import { AUTH_PRODUCT_NOT_READY, VERIFICATION_EXPIRED } from './auth';
import { INTERNAL_SERVER_ERROR, PLANNED_MAINTENANCE } from './api';
import {
  SANDBOX_WEBHOOK_INVALID,
  SANDBOX_PRODUCT_NOT_ENABLED,
  SANDBOX_BANK_TRANSFER_EVENT_TRANSITION_INVALID,
  SANDBOX_TRANSFER_EVENT_TRANSITION_INVALID,
  SANDBOX_ACCOUNT_SELECT_V2_NOT_ENABLED,
} from './sandbox';
import {
  INSTITUTION_DOWN,
  INSTITUTION_NOT_AVAILABLE,
  INSTITUTION_NOT_ENABLED_IN_ENVIRONMENT,
  INSTITUTION_NOT_FOUND,
  INSTITUTION_NOT_RESPONDING,
  INSTITUTION_NO_LONGER_SUPPORTED,
  INSTITUTION_REGISTRATION_REQUIRED,
  UNAUTHORIZED_INSTITUTION,
  UNSUPPORTED_RESPONSE,
} from './institution';

import {
  BANK_TRANSFER_LIMIT_EXCEEDED,
  BANK_TRANSFER_INSUFFICIENT_FUNDS,
  BANK_TRANSFER_INVALID_ORIGINATION_ACCOUNT,
  BANK_TRANSFER_MISSING_ORIGINATION_ACCOUNT,
  BANK_TRANSFER_ACCOUNT_BLOCKED,
  BANK_TRANSFER_UNSUPPORTED_ENVIRONMENT,
  BANK_TRANSFER_UNSUPPORTED_ACCOUNT_TYPE,
  BANK_TRANSFER_NOT_CANCELLABLE,
  BANK_TRANSFER_FORBIDDEN_ACH_CLASS,
} from './bankTransfers';

import { TRANSACTIONS_SYNC_MUTATION_DURING_PAGINATION } from './transactions';

import {
  TRANSFER_LIMIT_REACHED,
  TRANSFER_ACCOUNT_BLOCKED,
  TRANSFER_UNSUPPORTED_ACCOUNT_TYPE,
  TRANSFER_NOT_CANCELLABLE,
  TRANSFER_FORBIDDEN_ACH_CLASS,
  TRANSFER_UI_UNAUTHORIZED,
  PAYMENT_PROFILE_NOT_READY,
  INCOMPLETE_CUSTOMER_ONBOARDING,
  TRANSFER_ORIGINATOR_NOT_FOUND,
  UNAUTHORIZED_ACCESS,
} from './transfer';

import {
  PAYMENT_BLOCKED,
  PAYMENT_CANCELLED,
  PAYMENT_INSUFFICIENT_FUNDS,
  PAYMENT_INVALID_RECIPIENT,
  PAYMENT_INVALID_REFERENCE,
  PAYMENT_INVALID_SCHEDULE,
  PAYMENT_REJECTED,
  PAYMENT_SCHEME_NOT_SUPPORTED,
  PAYMENT_CONSENT_INVALID_CONSTRAINTS,
  PAYMENT_CONSENT_CANCELLED,
} from './payment';

import {
  TRANSACTION_INSUFFICIENT_FUNDS,
  TRANSACTION_EXECUTION_FAILED,
  NONIDENTICAL_REQUEST,
  REQUEST_CONFLICT,
  TRANSACTION_ON_SAME_ACCOUNT,
  TRANSACTION_CURRENCY_MISMATCH,
  TRANSACTION_IBAN_INVALID,
  TRANSACTION_BACS_INVALID,
  TRANSACTION_FAST_PAY_DISABLED,
  TRANSACTION_AMOUNT_EXCEEDED,
} from './virtualAccounts';

import {
  INVALID_PARENT,
  ASSET_REPORT_GENERATION_FAILED,
  DATA_UNAVAILABLE,
  ASSET_PRODUCT_NOT_ENABLED,
  ASSET_PRODUCT_NOT_READY,
  INSIGHTS_NOT_ENABLED,
  INSIGHTS_PREVIOUSLY_NOT_ENABLED,
  DATA_QUALITY_CHECK_FAILED,
} from './assets';

import {
  OAUTH_STATE_ID_ALREADY_PROCESSED,
  INCORRECT_OAUTH_NONCE,
  INCORRECT_LINK_TOKEN,
  OAUTH_FLOW_ALREADY_COMPLETED,
  MISSING_OAUTH_STATE_ID,
  OAUTH_STATE_ID_NOT_FOUND,
} from './oauth';

import {
  DEPOSIT_SWITCH_NOT_FOUND,
  DEPOSIT_SWITCH_TOKEN_NOT_FOUND,
  DEPOSIT_SWITCH_INVALID_ACCOUNT,
  DEPOSIT_SWITCH_VALID_AUTH_NOT_FOUND,
  DEPOSIT_SWITCH_VALID_IDENTITY_NOT_FOUND,
  DEPOSIT_SWITCH_ALREADY_COMPLETED,
  INVALID_DEPOSIT_SWITCH_ID,
} from './depositSwitch';

import {
  INCOME_VERIFICATION_PRODUCT_NOT_ENABLED,
  INCOME_VERIFICATION_UPLOAD_ERROR,
  INCOME_VERIFICATION_FAILED,
  VERIFICATION_STATUS_PENDING_APPROVAL,
  INCOME_VERIFICATION_DOCUMENT_NOT_FOUND,
  INCOME_VERIFICATION_NOT_FOUND,
  INCOME_VERIFICATION_PRODUCT_NOT_READY,
  EMPLOYMENT_NOT_FOUND,
} from './incomeVerification';

import {
  CUSTOMER_NOT_FOUND,
  FLOWDOWN_NOT_COMPLETE,
  QUESTIONNAIRE_NOT_COMPLETE,
  CUSTOMER_NOT_READY_FOR_ENABLEMENT,
  CUSTOMER_ALREADY_ENABLED,
  CUSTOMER_ALREADY_CREATED,
  LOGO_REQUIRED,
  INVALID_LOGO,
  CONTACT_REQUIRED,
  ASSETS_UNDER_MANAGEMENT_REQUIRED,
  CUSTOMER_REMOVAL_NOT_ALLOWED,
} from './partner';

import {
  ITEM_LOGIN_REQUIRED,
  ITEM_PRODUCT_NOT_READY,
  INSTANT_MATCH_FAILED,
  INSUFFICIENT_CREDENTIALS,
  INVALID_CREDENTIALS,
  INVALID_MFA_DEVICE,
  INVALID_SEND_METHOD,
  INVALID_MFA,
  INVALID_PHONE_NUMBER,
  INVALID_OTP,
  INVALID_UPDATED_USERNAME,
  ITEM_LOCKED,
  ITEM_NOT_SUPPORTED,
  ITEM_NOT_FOUND,
  ITEM_CONCURRENTLY_DELETED,
  USER_SETUP_REQUIRED,
  MFA_NOT_SUPPORTED,
  NO_ACCOUNTS,
  NO_AUTH_ACCOUNTS,
  NO_INVESTMENT_ACCOUNTS,
  NO_INVESTMENT_AUTH_ACCOUNTS, // TODO: undocumented in /docs todays
  NO_LIABILITY_ACCOUNTS,
  PRODUCT_NOT_ENABLED,
  PRODUCTS_NOT_SUPPORTED,
  ACCESS_NOT_GRANTED,
  USER_INPUT_TIMEOUT,
  PASSWORD_RESET_REQUIRED,
} from './item';

import {
  INVALID_API_KEYS,
  UNAUTHORIZED_ENVIRONMENT,
  UNAUTHORIZED_ROUTE_ACCESS,
  INVALID_ACCESS_TOKEN,
  INVALID_PUBLIC_TOKEN,
  INVALID_PRODUCT,
  INVALID_ACCOUNT_ID,
  INVALID_STRIPE_ACCOUNT,
  INVALID_INSTITUTION,
  INVALID_LINK_CUSTOMIZATION,
  INVALID_LINK_TOKEN,
  INVALID_AUDIT_COPY_TOKEN,
  INVALID_PROCESSOR_TOKEN,
  INVALID_CREDENTIAL_FIELDS,
  DIRECT_INTEGRATION_NOT_ENABLED,
  INCORRECT_DEPOSIT_VERIFICATION,
  TOO_MANY_VERIFICATION_ATTEMPTS,
  USER_MFA_EXPIRED,
  PLAID_DIRECT_INTEGRATION_NOT_ENABLED,
  INVALID_WEBHOOK_VERIFICATION_KEY_ID,
  USER_PERMISSION_REVOKED,
  INVALID_USER_TOKEN,
  ADDITIONAL_CONSENT_REQUIRED,
} from './invalidInput';

import {
  PLAID_DIRECT_ITEM_IMPORT_RETURNED_INVALID_MFA,
  LAST_UPDATED_DATETIME_OUT_OF_RANGE,
} from './invalidResult';

import {
  MISSING_FIELDS,
  UNKNOWN_FIELDS,
  INVALID_FIELD,
  INVALID_BODY,
  INVALID_HEADERS,
  INVALID_CONFIGURATION,
  INVALID_ACCOUNT_NUMBER,
  NOT_FOUND,
  SANDBOX_ONLY,
  INCOMPATIBLE_API_VERSION,
  NO_LONGER_AVAILABLE,
} from './invalidRequest';

import {
  ACCOUNTS_LIMIT,
  AUTH_LIMIT,
  IDENTITY_LIMIT,
  INCOME_LIMIT,
  INSTITUTIONS_GET_LIMIT,
  INSTITUTIONS_GET_BY_ID_LIMIT,
  INVESTMENT_HOLDINGS_GET_LIMIT,
  INVESTMENT_TRANSACTIONS_LIMIT,
  ITEM_GET_LIMIT,
  RATE_LIMIT,
  CREDITS_EXHAUSTED,
  ACCOUNTS_BALANCE_GET_LIMIT,
  TRANSACTIONS_LIMIT,
  TRANSACTIONS_SYNC_LIMIT,
  BALANCE_LIMIT,
} from './rateLimitExceeded';

import {
  CONSUMER_REPORT_EXPIRED,
  PRODUCT_NOT_READY,
  CHECK_REPORT_DATA_UNAVAILABLE,
  CHECK_REPORT_DATA_QUALITY_CHECK_FAILED,
  INSTITUTION_TRANSACTION_HISTORY_NOT_SUPPORTED,
  INSUFFICIENT_TRANSACTION_DATA,
  CHECK_REPORT_NO_ACCOUNTS,
  NETWORK_CONSENT_REQUIRED,
} from './checkReport';

import { RECAPTCHA_REQUIRED, RECAPTCHA_BAD } from './recaptcha';

export default {
  ITEM_ERROR: {
    ITEM_LOGIN_REQUIRED,
    PRODUCT_NOT_READY: ITEM_PRODUCT_NOT_READY,
    INSTANT_MATCH_FAILED,
    INSUFFICIENT_CREDENTIALS,
    INVALID_CREDENTIALS,
    INVALID_MFA_DEVICE,
    INVALID_MFA,
    INVALID_PHONE_NUMBER,
    INVALID_OTP,
    INVALID_SEND_METHOD,
    INVALID_UPDATED_USERNAME,
    ITEM_CONCURRENTLY_DELETED,
    ITEM_LOCKED,
    ITEM_NOT_SUPPORTED,
    ITEM_NOT_FOUND,
    INCORRECT_DEPOSIT_VERIFICATION,
    TOO_MANY_VERIFICATION_ATTEMPTS,
    USER_SETUP_REQUIRED,
    MFA_NOT_SUPPORTED,
    NO_ACCOUNTS,
    NO_AUTH_ACCOUNTS,
    NO_INVESTMENT_ACCOUNTS,
    NO_INVESTMENT_AUTH_ACCOUNTS,
    NO_LIABILITY_ACCOUNTS,
    PRODUCT_NOT_ENABLED,
    PRODUCTS_NOT_SUPPORTED,
    ACCESS_NOT_GRANTED,
    USER_INPUT_TIMEOUT,
    PASSWORD_RESET_REQUIRED,
  },
  API_ERROR: {
    INTERNAL_SERVER_ERROR,
    PLANNED_MAINTENANCE,
  },
  SANDBOX_ERROR: {
    SANDBOX_WEBHOOK_INVALID,
    SANDBOX_PRODUCT_NOT_ENABLED,
    SANDBOX_BANK_TRANSFER_EVENT_TRANSITION_INVALID,
    SANDBOX_TRANSFER_EVENT_TRANSITION_INVALID,
    SANDBOX_ACCOUNT_SELECT_V2_NOT_ENABLED,
  },
  INSTITUTION_ERROR: {
    INSTITUTION_DOWN,
    INSTITUTION_NOT_RESPONDING,
    INSTITUTION_NOT_AVAILABLE,
    INSTITUTION_NO_LONGER_SUPPORTED,
    UNAUTHORIZED_INSTITUTION,
    INSTITUTION_REGISTRATION_REQUIRED,
    INSTITUTION_NOT_ENABLED_IN_ENVIRONMENT,
    INSTITUTION_NOT_FOUND,
    UNSUPPORTED_RESPONSE,
  },
  AUTH_ERROR: {
    PRODUCT_NOT_READY: AUTH_PRODUCT_NOT_READY,
    VERIFICATION_EXPIRED,
  },
  ASSET_REPORT_ERROR: {
    INVALID_PARENT,
    ASSET_REPORT_GENERATION_FAILED,
    DATA_UNAVAILABLE,
    PRODUCT_NOT_ENABLED: ASSET_PRODUCT_NOT_ENABLED,
    PRODUCT_NOT_READY: ASSET_PRODUCT_NOT_READY,
    INSIGHTS_NOT_ENABLED,
    INSIGHTS_PREVIOUSLY_NOT_ENABLED,
    DATA_QUALITY_CHECK_FAILED,
  },
  INVALID_INPUT: {
    INVALID_API_KEYS,
    UNAUTHORIZED_ENVIRONMENT,
    UNAUTHORIZED_ROUTE_ACCESS,
    INVALID_ACCESS_TOKEN,
    INVALID_PUBLIC_TOKEN,
    INVALID_PRODUCT,
    INVALID_ACCOUNT_ID,
    INVALID_STRIPE_ACCOUNT,
    INVALID_INSTITUTION,
    INVALID_LINK_CUSTOMIZATION,
    INVALID_LINK_TOKEN,
    INVALID_AUDIT_COPY_TOKEN,
    INVALID_PROCESSOR_TOKEN,
    INVALID_CREDENTIAL_FIELDS,
    INVALID_USER_TOKEN,
    DIRECT_INTEGRATION_NOT_ENABLED,
    INCORRECT_DEPOSIT_VERIFICATION,
    TOO_MANY_VERIFICATION_ATTEMPTS,
    USER_MFA_EXPIRED,
    PLAID_DIRECT_INTEGRATION_NOT_ENABLED,
    INVALID_WEBHOOK_VERIFICATION_KEY_ID,
    USER_PERMISSION_REVOKED,
    ADDITIONAL_CONSENT_REQUIRED,
  },

  INVALID_REQUEST: {
    MISSING_FIELDS,
    UNKNOWN_FIELDS,
    INVALID_FIELD,
    INVALID_BODY,
    INVALID_HEADERS,
    INVALID_CONFIGURATION,
    INVALID_ACCOUNT_NUMBER,
    NOT_FOUND,
    SANDBOX_ONLY,
    INCOMPATIBLE_API_VERSION,
    NO_LONGER_AVAILABLE,
  },
  RATE_LIMIT_EXCEEDED: {
    ACCOUNTS_LIMIT,
    AUTH_LIMIT,
    IDENTITY_LIMIT,
    INCOME_LIMIT,
    INSTITUTIONS_GET_LIMIT,
    INSTITUTIONS_GET_BY_ID_LIMIT,
    INVESTMENT_HOLDINGS_GET_LIMIT,
    INVESTMENT_TRANSACTIONS_LIMIT,
    ITEM_GET_LIMIT,
    RATE_LIMIT,
    CREDITS_EXHAUSTED,
    BALANCE_LIMIT,
    TRANSACTIONS_LIMIT,
    TRANSACTIONS_SYNC_LIMIT,
    ACCOUNTS_BALANCE_GET_LIMIT,
  },

  RECAPTCHA_ERROR: {
    RECAPTCHA_REQUIRED,
    RECAPTCHA_BAD,
  },

  PAYMENT_ERROR: {
    PAYMENT_BLOCKED,
    PAYMENT_CANCELLED,
    PAYMENT_INSUFFICIENT_FUNDS,
    PAYMENT_INVALID_RECIPIENT,
    PAYMENT_INVALID_REFERENCE,
    PAYMENT_INVALID_SCHEDULE,
    PAYMENT_REJECTED,
    PAYMENT_SCHEME_NOT_SUPPORTED,
    PAYMENT_CONSENT_INVALID_CONSTRAINTS,
    PAYMENT_CONSENT_CANCELLED,
  },

  VIRTUAL_ACCOUNTS_ERROR: {
    TRANSACTION_INSUFFICIENT_FUNDS,
    TRANSACTION_EXECUTION_FAILED,
    NONIDENTICAL_REQUEST,
    REQUEST_CONFLICT,
    TRANSACTION_ON_SAME_ACCOUNT,
    TRANSACTION_CURRENCY_MISMATCH,
    TRANSACTION_IBAN_INVALID,
    TRANSACTION_BACS_INVALID,
    TRANSACTION_FAST_PAY_DISABLED,
    TRANSACTION_AMOUNT_EXCEEDED,
  },

  INVALID_RESULT: {
    PLAID_DIRECT_ITEM_IMPORT_RETURNED_INVALID_MFA,
    LAST_UPDATED_DATETIME_OUT_OF_RANGE,
  },

  DEPOSIT_SWITCH_ERROR: {
    DEPOSIT_SWITCH_NOT_FOUND,
    DEPOSIT_SWITCH_TOKEN_NOT_FOUND,
    DEPOSIT_SWITCH_INVALID_ACCOUNT,
    DEPOSIT_SWITCH_VALID_AUTH_NOT_FOUND,
    DEPOSIT_SWITCH_VALID_IDENTITY_NOT_FOUND,
    DEPOSIT_SWITCH_ALREADY_COMPLETED,
    INVALID_DEPOSIT_SWITCH_ID,
  },

  INCOME_VERIFICATION_ERROR: {
    INCOME_VERIFICATION_DOCUMENT_NOT_FOUND,
    INCOME_VERIFICATION_FAILED,
    INCOME_VERIFICATION_NOT_FOUND,
    INCOME_VERIFICATION_UPLOAD_ERROR,
    PRODUCT_NOT_ENABLED: INCOME_VERIFICATION_PRODUCT_NOT_ENABLED,
    PRODUCT_NOT_READY: INCOME_VERIFICATION_PRODUCT_NOT_READY,
    VERIFICATION_STATUS_PENDING_APPROVAL,
    EMPLOYMENT_NOT_FOUND,
  },

  BANK_TRANSFER_ERROR: {
    BANK_TRANSFER_ACCOUNT_BLOCKED,
    BANK_TRANSFER_INSUFFICIENT_FUNDS,
    BANK_TRANSFER_MISSING_ORIGINATION_ACCOUNT,
    BANK_TRANSFER_LIMIT_EXCEEDED,
    BANK_TRANSFER_INVALID_ORIGINATION_ACCOUNT,
    BANK_TRANSFER_UNSUPPORTED_ENVIRONMENT,
    BANK_TRANSFER_UNSUPPORTED_ACCOUNT_TYPE,
    BANK_TRANSFER_NOT_CANCELLABLE,
    BANK_TRANSFER_FORBIDDEN_ACH_CLASS,
  },

  TRANSACTIONS_ERROR: {
    TRANSACTIONS_SYNC_MUTATION_DURING_PAGINATION,
  },

  TRANSFER_ERROR: {
    TRANSFER_LIMIT_REACHED,
    TRANSFER_ACCOUNT_BLOCKED,
    TRANSFER_UNSUPPORTED_ACCOUNT_TYPE,
    TRANSFER_NOT_CANCELLABLE,
    TRANSFER_FORBIDDEN_ACH_CLASS,
    TRANSFER_UI_UNAUTHORIZED,
    PAYMENT_PROFILE_NOT_READY,
    INCOMPLETE_CUSTOMER_ONBOARDING,
    TRANSFER_ORIGINATOR_NOT_FOUND,
    UNAUTHORIZED_ACCESS,
  },

  OAUTH_ERROR: {
    INCORRECT_OAUTH_NONCE,
    INCORRECT_LINK_TOKEN,
    OAUTH_FLOW_ALREADY_COMPLETED,
    OAUTH_STATE_ID_ALREADY_PROCESSED,
    MISSING_OAUTH_STATE_ID,
    OAUTH_STATE_ID_NOT_FOUND,
  },

  PARTNER_ERROR: {
    CUSTOMER_NOT_FOUND,
    FLOWDOWN_NOT_COMPLETE,
    QUESTIONNAIRE_NOT_COMPLETE,
    CUSTOMER_NOT_READY_FOR_ENABLEMENT,
    CUSTOMER_ALREADY_ENABLED,
    CUSTOMER_ALREADY_CREATED,
    LOGO_REQUIRED,
    INVALID_LOGO,
    CONTACT_REQUIRED,
    ASSETS_UNDER_MANAGEMENT_REQUIRED,
    CUSTOMER_REMOVAL_NOT_ALLOWED,
  },
  CHECK_REPORT_ERROR: {
    CONSUMER_REPORT_EXPIRED,
    PRODUCT_NOT_READY,
    CHECK_REPORT_DATA_UNAVAILABLE,
    CHECK_REPORT_DATA_QUALITY_CHECK_FAILED,
    INSTITUTION_TRANSACTION_HISTORY_NOT_SUPPORTED,
    INSUFFICIENT_TRANSACTION_DATA,
    CHECK_REPORT_NO_ACCOUNTS,
    NETWORK_CONSENT_REQUIRED,
  },
};
