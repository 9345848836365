import { toCamelCase, capitalizeFirstLetter, formatComment } from './utils';

const javaEnumTypes = {
  products: 'Products',
  country_codes: 'CountryCode',
  additional_consented_products: 'Products',
  required_if_supported_products: 'Products',
  optional_products: 'Products',
  'account_filters.depository.account_subtypes': 'DepositoryAccountSubtype',
  'account_filters.credit.account_subtypes': 'CreditAccountSubtype',
  'income_verification.income_source_types': 'IncomeVerificationSourceType',
  'income_verification.payroll_income.flow_types':
    'IncomeVerificationPayrollFlowType',
};

const javaSingleValueEnums = {
  consumer_report_permissible_purpose: 'ConsumerReportPermissiblePurpose',
};

const javaEnumStripCommonPrefix = {
  // See https://github.com/swagger-api/swagger-codegen-generators/issues/246#issuecomment-442629121
  // for why we need this
  'income_verification.payroll_income.flow_types': 'PAYROLL_',
};

const javaTypes = {
  user: 'LinkTokenCreateRequestUser',
  address: 'UserAddress',
  account_filters: 'LinkTokenAccountFilters',
  'account_filters.depository': 'DepositoryFilter',
  'account_filters.credit': 'CreditFilter',

  auth: 'LinkTokenCreateRequestAuth',
  transactions: 'LinkTokenTransactions',
  payment_initiation: 'LinkTokenCreateRequestPaymentInitiation',
  statements: 'LinkTokenCreateRequestStatements',
  identity_verification: 'LinkTokenCreateRequestIdentityVerification',
  income_verification: 'LinkTokenCreateRequestIncomeVerification',
  'income_verification.bank_income':
    'LinkTokenCreateRequestIncomeVerificationBankIncome',
  'income_verification.payroll_income':
    'LinkTokenCreateRequestIncomeVerificationPayrollIncome',
  cra_options: 'LinkTokenCreateRequestCraOptions',
  'cra_options.base_report': 'LinkTokenCreateRequestCraOptionsBaseReport',
  'cra_options.partner_insights':
    'LinkTokenCreateRequestCraOptionsPartnerInsights',
};

const stringsThatShouldBeDates = [
  'statements.start_date',
  'statements.end_date',
];

// TODO: I'll want to put in the same indent fix as I did for the Java version,
// if or when I run across a use case that need it
const formatArray = (
  path,
  elements,
  maxElementsPerLine = 1,
  maxCharsPerLine = 50,
) => {
  const enumType = javaEnumTypes[path];
  const arrayElements = elements
    .map(
      (v) =>
        `${(javaEnumStripCommonPrefix[path]
          ? v.toUpperCase().replace(javaEnumStripCommonPrefix[path], '')
          : v.toUpperCase()
        ).replace(/ /g, '_')}`,
    )
    .map((v) => `${enumType ? enumType + '.' : ''}${v.toUpperCase()}`);
  if (
    elements.length <= maxElementsPerLine &&
    JSON.stringify(elements).length <= maxCharsPerLine
  ) {
    const valuesStr = arrayElements.join(', ');
    return `Arrays.asList(${valuesStr})`;
  } else {
    const valuesStr = arrayElements.join(',\n     ');
    return `Arrays.asList(\n     ${valuesStr}\n  )`;
  }
};

export const createJavaSampleCode = (config: object, comment: string) => {
  const indent = '  '; // Add one level of indentation to properties of LinkTokenCreateRequest

  const createdIntermediaries: any = {};

  const formatValue = (
    value: any,
    path: string,
    requireIntermediary: boolean = false,
  ) => {
    const typeName = javaTypes[path] || toCamelCase(path.split('.').pop());
    if (Array.isArray(value)) {
      return formatArray(path, value);
    } else if (typeof value === 'object' && javaTypes[path]) {
      const varName = createdIntermediaries[path];
      if (varName === undefined && requireIntermediary) {
        throw new Error(`No intermediary created for ${path}`);
      }
      return varName || '';
    } else if (typeof value === 'object') {
      // Capitalize the typeName
      const propsStr = Object.entries(value)
        .map(
          ([k, v]) => `.${toCamelCase(k)}(${formatValue(v, `${path}.${k}`)})`,
        )
        .join('\n  ');
      return `new ${capitalizeFirstLetter(typeName)}()\n  ${propsStr}`;
    } else if (javaSingleValueEnums[path]) {
      return `${javaSingleValueEnums[path]}.${value}`;
    } else if (typeof value === 'boolean') {
      return `${value}`;
    } else if (typeof value === 'number') {
      return `${value}`;
    } else if (stringsThatShouldBeDates.includes(path)) {
      return `LocalDate.parse("${value}")`;
    } else {
      return `"${value}"`;
    }
  };

  // Java (and Go) are complicated because in our sample code, we generally
  // construct intermediary types before we pass them in to the final config
  // object. And it gets further complicated because those intermediary types
  // can have their own intermediary types. Sigh.
  const createAndFormatIntermediaries = (obj: any, path: string = '') => {
    let declarations = [];
    let initializations = [];

    for (let [key, value] of Object.entries(obj)) {
      const fullPath = path ? `${path}${key}` : key;

      // Special handling for address within user
      if (
        fullPath === 'user' &&
        value &&
        typeof value === 'object' &&
        'address' in (value as Record<string, any>)
      ) {
        // Process the user object first
        if (javaTypes[fullPath]) {
          const typeName = javaTypes[fullPath];
          const varName = toCamelCase(key);
          createdIntermediaries[fullPath] = varName;

          declarations.push(`${typeName} ${varName}`);

          // Handle user object properties except address
          const userProps = { ...(value as Record<string, any>) };
          delete userProps.address;

          const propsStr = Object.entries(userProps)
            .map(
              ([k, v]) =>
                `.${toCamelCase(k)}(${formatValue(v, `${fullPath}.${k}`)})`,
            )
            .join('\n  ');
          initializations.push(
            `${varName} = new ${typeName}()\n  ${propsStr};`,
          );
        }

        // Now process the address separately
        const valueWithAddress = value as Record<string, any>;
        if (
          typeof valueWithAddress.address === 'object' &&
          valueWithAddress.address &&
          javaTypes['address']
        ) {
          const addressPath = `${fullPath}.address`;
          const addressTypeName = javaTypes['address'];
          const addressVarName = toCamelCase('address');
          createdIntermediaries[addressPath] = addressVarName;

          declarations.push(`${addressTypeName} ${addressVarName}`);

          const addressPropsStr = Object.entries(
            valueWithAddress.address as Record<string, any>,
          )
            .map(
              ([k, v]) =>
                `.${toCamelCase(k)}(${formatValue(v, `${addressPath}.${k}`)})`,
            )
            .join('\n  ');
          initializations.push(
            `${addressVarName} = new ${addressTypeName}()\n  ${addressPropsStr};`,
          );
        }

        continue; // Skip standard processing since we've handled it specially
      }

      // If the value is an object, we need to process its properties first.
      if (typeof value === 'object' && value !== null) {
        const [nestedDeclarations, nestedInitializations] =
          createAndFormatIntermediaries(value, `${fullPath}.`);
        declarations = [...declarations, ...nestedDeclarations];
        initializations = [...initializations, ...nestedInitializations];
      }

      if (javaTypes[fullPath]) {
        const typeName = javaTypes[fullPath];
        const varName = toCamelCase(key);
        createdIntermediaries[fullPath] = varName;

        // Declare the variable
        declarations.push(`${typeName} ${varName}`);

        // Initialize the variable with its properties
        if (typeof value === 'object' && value !== null) {
          const propsStr = Object.entries(value)
            .map(
              ([k, v]) =>
                `.${toCamelCase(k)}(${formatValue(v, `${fullPath}.${k}`)})`,
            )
            .join('\n  ');
          initializations.push(
            `${varName} = new ${typeName}()\n  ${propsStr};`,
          );
        } else {
          initializations.push(`${varName} = ${formatValue(value, fullPath)};`);
        }
      }
    }

    return [declarations, initializations];
  };

  const combineDeclarationsAndInitializations = (
    declarations: string[],
    initializations: string[],
  ) => {
    return declarations
      .map((declaration, index) => {
        return `${declaration.split(';')[0]} = ${
          initializations[index].split('=')[1]
        }`;
      })
      .join('\n\n');
  };

  const [intermediaryDeclarations, intermediaryInitializations] =
    createAndFormatIntermediaries(config);

  const fullIntermediaryStr = combineDeclarationsAndInitializations(
    intermediaryDeclarations,
    intermediaryInitializations,
  );

  // Special handling for address on user object
  let userAddressCode = '';
  if (
    config &&
    typeof config === 'object' &&
    'user' in config &&
    config.user &&
    typeof config.user === 'object' &&
    'address' in (config.user as Record<string, any>)
  ) {
    const addressVarName = createdIntermediaries['user.address'] || 'address';
    userAddressCode = `\nuser.setAddress(${addressVarName});`;
  }

  // Format the configuration object into Java code
  const configStr = Object.entries(config)
    .map(([key, value]) => {
      if (key === 'user' && userAddressCode) {
        // Skip user configuration if we're handling address specially
        return '';
      } else if (javaTypes[key]) {
        return `.${toCamelCase(key)}(${formatValue(value, key, true)})`;
      } else {
        return `.${toCamelCase(key)}(${formatValue(value, key, false)})`;
      }
    })
    .filter((line) => line !== '') // Remove empty lines
    .join(`\n${indent}`);

  // Combine everything into a single multiline string
  return `${formatComment(comment, '// ')}
${fullIntermediaryStr}${userAddressCode}

LinkTokenCreateRequest request = new LinkTokenCreateRequest()
${indent}${configStr};

Response<LinkTokenCreateResponse> response = client
${indent}.linkTokenCreate(request)
${indent}.execute();

String linkToken = response.body().getLinkToken();
`.trim();
};
