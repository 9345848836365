import { formatComment } from './utils';

// Some elements inside of arrays are actually classes instead of strings.
// But not all of them! So we have to hard code that list here,
const pythonArrayElementTypes = {
  products: 'Products',
  country_codes: 'CountryCode',
  additional_consented_products: 'Products',
  required_if_supported_products: 'Products',
  optional_products: 'Products',
  'account_filters.depository.account_subtypes': 'DepositoryAccountSubtype',
  'account_filters.credit.account_subtypes': 'CreditAccountSubtype',
  'income_verification.income_source_types': 'IncomeVerificationSourceType',
  'income_verification.payroll_income.flow_types':
    'IncomeVerificationPayrollFlowType',
};

const pythonSingleValueEnums = {
  consumer_report_permissible_purpose: 'ConsumerReportPermissiblePurpose',
};

// The same holds true for many objects. They're often pre-defined classes
// that we can declare here.
const pythonTypes = {
  user: 'LinkTokenCreateRequestUser',
  address: 'UserAddress',
  account_filters: 'LinkTokenAccountFilters',
  'account_filters.depository': 'DepositoryFilter',
  'account_filters.credit': 'CreditFilter',
  'account_filters.depository.account_subtypes': 'DepositoryAccountSubtypes',
  'account_filters.credit.account_subtypes': 'CreditAccountSubtypes',
  auth: 'LinkTokenCreateRequestAuth',
  transactions: 'LinkTokenTransactions',
  payment_initiation: 'LinkTokenCreateRequestPaymentInitiation',
  statements: 'LinkTokenCreateRequestStatements',
  identity_verification: 'LinkTokenCreateRequestIdentityVerification',
  income_verification: 'LinkTokenCreateRequestIncomeVerification',
  'income_verification.bank_income':
    'LinkTokenCreateRequestIncomeVerificationBankIncome',
  'income_verification.payroll_income':
    'LinkTokenCreateRequestIncomeVerificationPayrollIncome',
  cra_options: 'LinkTokenCreateRequestCraOptions',
  'cra_options.base_report': 'LinkTokenCreateRequestCraOptionsBaseReport',
  'cra_options.partner_insights':
    'LinkTokenCreateRequestCraOptionsPartnerInsights',
};

const stringsThatShouldBeDates = [
  'statements.start_date',
  'statements.end_date',
];

const formatArrayElem = (arrayElementTypeName, v) => {
  // The difference between returning 'US' and CountryCode('US')
  return arrayElementTypeName ? `${arrayElementTypeName}('${v}')` : `'${v}'`;
};

const formatArray = (
  path,
  elements,
  indent,
  maxElementsPerLine = 2,
  maxCharsPerLine = 40,
) => {
  const typeName = pythonTypes[path];
  const arrayElementTypeName = pythonArrayElementTypes[path];
  const indents = ' '.repeat(indent);

  if (typeName) {
    // If there's a typename, we do one per line anyway
    return `${typeName}([\n${elements
      .map((v) => `${indents} ${formatArrayElem(arrayElementTypeName, v)}`)
      .join(',\n')}\n${' '.repeat(indent - 2)}])`;
  }
  if (
    elements.length <= maxElementsPerLine &&
    JSON.stringify(elements).length <= maxCharsPerLine
  ) {
    // Will it fit on one line?
    return `[${elements
      .map((v) => `${formatArrayElem(arrayElementTypeName, v)}`)
      .join(', ')}]`;
  } else {
    return `[\n${elements
      .map((v) => `${indents} ${formatArrayElem(arrayElementTypeName, v)}`)
      .join(',\n')}\n${' '.repeat(indent - 2)}]`;
  }
};

export const createPythonSampleCode = (config: object, comment: string) => {
  const formatValue = (value, path, indent = 4) => {
    const typeName = pythonTypes[path];
    const arrayElementTypeName = pythonArrayElementTypes[path];
    const indents = ' '.repeat(indent);
    if (Array.isArray(value)) {
      return formatArray(path, value, indent);
    } else if (typeof value === 'object') {
      // Recursively format object properties
      const propsStr = Object.entries(value)
        .map(
          ([k, v]) =>
            `${indents}${k}=${formatValue(v, `${path}.${k}`, indent + 2)}`,
        )
        .join(',\n');
      return typeName
        ? `${typeName}(\n${propsStr}\n${' '.repeat(indent - 2)})`
        : `{\n${propsStr}\n${' '.repeat(indent - 2)}}`;
    } else if (pythonSingleValueEnums[path]) {
      return `${
        pythonSingleValueEnums[path]
      }(\n${indents}'${value}'\n${' '.repeat(indent - 2)})`;
    } else if (typeof value === 'number') {
      return `${value}`;
    } else if (typeof value === 'boolean') {
      return value ? 'True' : 'False';
    } else if (stringsThatShouldBeDates.includes(path)) {
      return `date.fromisoformat('${value}')`;
    } else {
      return `'${value}'`;
    }
  };

  const configStr = Object.entries(config)
    .map(([key, value]) => `  ${key}=${formatValue(value, key, 4)}`)
    .join(',\n');

  return `${formatComment(comment, '# ')}
request = LinkTokenCreateRequest(
${configStr}
)
# create link token
response = client.link_token_create(request)
link_token = response['link_token']
`.trim();
};
