
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <MultiCodeBlock type='server' mdxType="MultiCodeBlock">
      <pre><code parentName="pre" {...{
          "className": "language-node",
          "metastring": "#server.js",
          "#server.js": true
        }}>{`app.post('/api/create_session_token', async function (request, response) {
  // Get the client_user_id by searching for the current user
  const user = await User.find(...);
  const clientUserId = user.id;
  const request = {
    user: {
      // This should correspond to a unique id for the current user.
      client_user_id: clientUserId,
    },
    template_id: TEMPLATE_ID,
  };
  try {
    const createTokenResponse = await client.sessionTokenCreate(request);
    response.json(createTokenResponse.data);
  } catch (error) {
    // handle error
  }
});
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`curl -X POST https://sandbox.plaid.com/session/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "template_id": "\${TEMPLATE_ID}"
}'
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-ruby",
          "metastring": "#server.rb",
          "#server.rb": true
        }}>{`post '/api/create_session_token' do
  # Get the client_user_id by searching for the current user
  current_user = User.find(...)
  client_user_id = current_user.id

  # Create a session_token for the given user
  request = Plaid::SessionTokenCreateRequest.new(
    {
      user: { client_user_id: client_user_id },
      template_id: TEMPLATE_ID
    }
  )
  response = client.session_token_create(request)
  content_type :json
  response.to_json
end
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-java",
          "metastring": "#Server.java",
          "#Server.java": true
        }}>{`import com.plaid.client.model.SessionTokenCreateRequest;
import com.plaid.client.model.SessionTokenCreateRequestUser;
import com.plaid.client.model.SessionTokenCreateResponse;

public class PlaidExample {

  ...
  static class GetSessionToken implements HttpHandler {
    private static PlaidApi plaidClient;

    public void handle(HttpExchange t) throws IOException {
      // Create your Plaid client
      HashMap<String, String> apiKeys = new HashMap<String, String>();
      apiKeys.put("clientId", CLIENT_ID);
      apiKeys.put("secret", SECRET);
      ApiClient apiClient = new ApiClient(apiKeys);
      apiClient.setPlaidAdapter(ApiClient.Sandbox);

      plaidClient = apiClient.createService(PlaidApi.class);

      // Get the clientUserId by searching for the current user
      User userFromDB = db.find(...);
      String clientUserId = userFromDB.id;
      SessionTokenCreateRequestUser user = new SessionTokenCreateRequestUser()
        .clientUserId(clientUserId);

      // Create a session_token for the given user
      SessionTokenCreateRequest request = new SessionTokenCreateRequest()
        .user(user)
        .templateId(TEMPLATE_ID);

      Response<SessionTokenCreateResponse> response = plaidClient
        .sessionTokenCreate(request)
        .execute();

      // Send the data to the client
      return response.body();
    }
  }
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-python",
          "metastring": "#server.py",
          "#server.py": true
        }}>{`from plaid.model.session_token_create_request import SessionTokenCreateRequest
from plaid.model.session_token_create_request_user import SessionTokenCreateRequestUser

@app.route("/create_session_token", methods=['POST'])
def create_session_token():
    # Get the client_user_id by searching for the current user
    user = User.find(...)
    client_user_id = user.id

    # Create a session_token for the given user
    request = SessionTokenCreateRequest(
            user=SessionTokenCreateRequestUser(
                client_user_id=client_user_id
            ),
            template_id=TEMPLATE_ID
        )
    response = client.session_token_create(request)

    # Send the data to the client
    return jsonify(response.to_dict())

`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-go",
          "metastring": "#server.go",
          "#server.go": true
        }}>{`func createSessionToken(c *gin.Context) {
  ctx := context.Background()

  // Get the client_user_id by searching for the current user
  user, _ := usermodels.Find(...)
  clientUserId := user.ID.String()

  // Create a session_token for the given user
  request := plaid.NewSessionTokenCreateRequest(TEMPLATE_ID, *plaid.NewSessionTokenCreateRequestUser(clientUserId))

  resp, _, err := testClient.PlaidApi.SessionTokenCreate(ctx).SessionTokenCreateRequest(*request).Execute()

  // Send the data to the client
  c.JSON(http.StatusOK, gin.H{
    "session_token": resp.GetLink().GetLinkToken(),
  })
}

`}</code></pre>
    </MultiCodeBlock>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
