import React from 'react';

import styles from './SmallWrapper.module.scss';

interface Props {
  children: Array<React.ReactNode>;
  miniTitle?: string;
}

const SmallWrapper: React.FC<Props> = (props: Props) => (
  <>
    {props.miniTitle && (
      <div className={styles.miniTitle}>{props.miniTitle}</div>
    )}
    <div className={styles.smallWrapper} data-algolia-remove='true'>
      {props.children}
    </div>
  </>
);

SmallWrapper.displayName = 'SmallWrapper';

export default SmallWrapper;
