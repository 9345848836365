import { ErrorReference } from '../../types';

export const CONSUMER_REPORT_EXPIRED: ErrorReference = {
  server: {
    error_type: 'CONSUMER_REPORT_ERROR',
    error_code: 'CONSUMER_REPORT_EXPIRED',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const PRODUCT_NOT_READY: ErrorReference = {
  server: {
    error_type: 'CONSUMER_REPORT_ERROR',
    error_code: 'PRODUCT_NOT_READY',
    error_message:
      'The consumer report you are trying to pull is not ready. Please wait for a CHECK_REPORT_READY webhook to fetch data',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const CHECK_REPORT_DATA_UNAVAILABLE: ErrorReference = {
  server: {
    http_code: 400,
    display_message: null,
    error_code: 'DATA_UNAVAILABLE',
    error_message:
      'The Check Report you are trying to pull does not have sufficient transactions data to generate a report.',
    error_type: 'CHECK_REPORT_ERROR',
    suggested_action: null,
    causes: [
      {
        display_message: null,
        error_code: 'ITEM_LOGIN_REQUIRED',
        error_message:
          "The login details of this item have changed (credentials, MFA, or required user action) and a user login is required to update this information. use Link's update mode to restore the item to a good state",
        error_type: 'ITEM_ERROR',
        item_id: 'pZ942ZA0npFEa0BgLCV9DAQv3Zq8ErIZhc81F',
      },
    ],
  },
  link: null,
};

export const CHECK_REPORT_DATA_QUALITY_CHECK_FAILED: ErrorReference = {
  server: {
    error_type: 'CHECK_REPORT_ERROR',
    error_code: 'DATA_QUALITY_CHECK_FAILED',
    error_message:
      'Bank provided inconsistent transaction history data that has a high chance of error. The bank will need to resolve issues for product data to be generated.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INSTITUTION_TRANSACTION_HISTORY_NOT_SUPPORTED: ErrorReference = {
  server: {
    error_type: 'CHECK_REPORT_ERROR',
    error_code: 'INSTITUTION_TRANSACTION_HISTORY_NOT_SUPPORTED',
    error_message:
      'The user’s bank does not support the transactions range you require. Lowering the days_required may result in success.',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const INSUFFICIENT_TRANSACTION_DATA: ErrorReference = {
  server: {
    error_type: 'CHECK_REPORT_ERROR',
    error_code: 'INSUFFICIENT_TRANSACTION_DATA',
    error_message: '',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const CHECK_REPORT_NO_ACCOUNTS: ErrorReference = {
  server: {
    error_type: 'CHECK_REPORT_ERROR',
    error_code: 'NO_ACCOUNTS',
    error_message: 'No depository accounts were found for this user',
    display_message: null,
    http_code: 400,
  },
  link: null,
};

export const NETWORK_CONSENT_REQUIRED: ErrorReference = {
  server: {
    error_type: 'CHECK_REPORT_ERROR',
    error_code: 'NETWORK_CONSENT_REQUIRED',
    error_message: 'User has not provided consent to share network data',
    display_message: null,
    http_code: 400,
  },
  link: null,
};
