
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <MultiCodeBlock type='server' templateProps={{
      product: props.product,
      additional_consented_products: props.additional_consented_products,
      goProduct: props.product.toUpperCase(),
      goAdditionalConsentedProducts: props.additional_consented_products && props.additional_consented_products.toUpperCase()
    }} mdxType="MultiCodeBlock">
      <pre><code parentName="pre" {...{
          "className": "language-node",
          "metastring": "#server.js",
          "#server.js": true
        }}>{`app.post('/api/create_link_token', async function (request, response) {
  // Get the client_user_id by searching for the current user
  const user = await User.find(...);
  const clientUserId = user.id;
  const request = {
    user: {
      // This should correspond to a unique id for the current user.
      client_user_id: clientUserId,
    },
    client_name: 'Plaid Test App',
    products: ['{{{product}}}'],
    language: 'en',
    webhook: 'https://webhook.example.com',
    redirect_uri: 'https://domainname.com/oauth-page.html',
    country_codes: ['US'],
  };
  try {
    const createTokenResponse = await client.linkTokenCreate(request);
    response.json(createTokenResponse.data);
  } catch (error) {
    // handle error
  }
});
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`curl -X POST https://sandbox.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "Plaid Test App",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "products": ["{{{product}}}"],
  "country_codes": ["US"],
  "language": "en",
  "webhook": "https://webhook.example.com",
  "redirect_uri": "https://domainname.com/oauth-page.html"
}'
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-ruby",
          "metastring": "#server.rb",
          "#server.rb": true
        }}>{`post '/api/create_link_token' do
  # Get the client_user_id by searching for the current user
  current_user = User.find(...)
  client_user_id = current_user.id

  # Create a link_token for the given user
  request = Plaid::LinkTokenCreateRequest.new(
    {
      user: { client_user_id: client_user_id },
      client_name: 'Plaid Test App',
      products: ['{{{product}}}'],
      country_codes: ['US'],
      language: "en",
      redirect_uri: nil_if_empty_envvar('PLAID_REDIRECT_URI'),
      webhook: 'https://webhook.example.com'
    }
  )
  response = client.link_token_create(request)
  content_type :json
  response.to_json
end
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-java",
          "metastring": "#Server.java",
          "#Server.java": true
        }}>{`import com.plaid.client.model.Products;
import com.plaid.client.model.CountryCode;
import com.plaid.client.model.LinkTokenCreateRequest;
import com.plaid.client.model.LinkTokenCreateRequestUser;
import com.plaid.client.model.LinkTokenCreateResponse;

public class PlaidExample {

  ...
  static class GetLinkToken implements HttpHandler {
    private static PlaidApi plaidClient;

    public void handle(HttpExchange t) throws IOException {
      // Create your Plaid client
      HashMap<String, String> apiKeys = new HashMap<String, String>();
      apiKeys.put("clientId", CLIENT_ID);
      apiKeys.put("secret", SECRET);
      ApiClient apiClient = new ApiClient(apiKeys);
      apiClient.setPlaidAdapter(ApiClient.Sandbox);

      plaidClient = apiClient.createService(PlaidApi.class);

      // Get the clientUserId by searching for the current user
      User userFromDB = db.find(...);
      String clientUserId = userFromDB.id;
      LinkTokenCreateRequestUser user = new LinkTokenCreateRequestUser()
        .clientUserId(clientUserId);

      // Create a link_token for the given user
      LinkTokenCreateRequest request = new LinkTokenCreateRequest()
        .user(user)
        .clientName("Plaid Test App")
        .products(Arrays.asList(Products.fromValue("{{{product}}}")))
        .countryCodes(Arrays.asList(CountryCode.US))
        .language("en")
        .redirectUri("https://domainname.com/oauth-page.html")
        .webhook("https://webhook.example.com");

      Response<LinkTokenCreateResponse> response = plaidClient
        .linkTokenCreate(request)
        .execute();

      // Send the data to the client
      return response.body();
    }
  }
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-python",
          "metastring": "#server.py",
          "#server.py": true
        }}>{`from plaid.model.link_token_create_request import LinkTokenCreateRequest
from plaid.model.link_token_create_request_user import LinkTokenCreateRequestUser
from plaid.model.products import Products
from plaid.model.country_code import CountryCode

@app.route("/create_link_token", methods=['POST'])
def create_link_token():
    # Get the client_user_id by searching for the current user
    user = User.find(...)
    client_user_id = user.id

    # Create a link_token for the given user
    request = LinkTokenCreateRequest(
            products=[Products("{{{product}}}")],
            client_name="Plaid Test App",
            country_codes=[CountryCode('US')],
            redirect_uri='https://domainname.com/oauth-page.html',
            language='en',
            webhook='https://webhook.example.com',
            user=LinkTokenCreateRequestUser(
                client_user_id=client_user_id
            )
        )
    response = client.link_token_create(request)

    # Send the data to the client
    return jsonify(response.to_dict())

`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-go",
          "metastring": "#server.go",
          "#server.go": true
        }}>{`func createLinkToken(c *gin.Context) {
  ctx := context.Background()

  // Get the client_user_id by searching for the current user
  user, _ := usermodels.Find(...)
  clientUserId := user.ID.String()

  // Create a link_token for the given user
  request := plaid.NewLinkTokenCreateRequest("Plaid Test App", "en", []plaid.CountryCode{plaid.COUNTRYCODE_US}, *plaid.NewLinkTokenCreateRequestUser(clientUserId))
  request.SetWebhook("https://webhook.sample.com")
  request.SetRedirectUri("https://domainname.com/oauth-page.html")
  request.SetProducts([]plaid.Products{plaid.PRODUCTS_{{{goProduct}}}})

  resp, _, err := testClient.PlaidApi.LinkTokenCreate(ctx).LinkTokenCreateRequest(*request).Execute()

  // Send the data to the client
  c.JSON(http.StatusOK, gin.H{
    "link_token": resp.GetLinkToken(),
  })
}

`}</code></pre>
    </MultiCodeBlock>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
